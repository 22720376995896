import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { EmailValidator } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/providers/config/config.service';
import { map, first } from "rxjs/operators";

import { HttpClient, HttpParams } from '@angular/common/http';
import { AngularSectionComponent } from 'src/app/sections/angular-section/angular-section.component';
import { NgbToast } from '@ng-bootstrap/ng-bootstrap';
 import { NewsletterService } from 'src/app/newsletter.service';
 import { NavbarService } from 'src/app/navbarservice.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {
  test: Date = new Date();

  subscribeEmail: String;
  show: Boolean;
  fail: Boolean;
  subText: String;

  year;

  mobile;


  constructor(private router: Router, private config: ConfigService, private http: HttpClient, private newsletter: NewsletterService, public nav: NavbarService) { }

  ngOnInit() {
    let date = new Date;
    this.year = date.getFullYear();

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  getPath() {
    return this.router.url;
  }

  submitFoot() {
    this.checkEmail(this.subscribeEmail);
  }
  

  async checkEmail(email) {
    if (email == null || email == "" || email.indexOf('@') == -1) {
      // console.log("Invalid, you must use a valid email address.");
      this.fail = true;
      this.subText = "Invalid, you must use a valid email address."
      // console.log(this.newsletter.getB64());
      setTimeout(() => (this.fail = false), 3000);
    } else {
      // console.log("success");
      // console.log(email);
      setTimeout(() => (this.show = false), 3000);

      var url = this.config.url;

      await this.http.post<any>(url + 'user/news', { email: email }).pipe(first()).subscribe(data => {
        // console.log('register sucess');
        this.show = true;
        this.subText = "Successfuly subscribed!"
        let body = {
          emails: email,
          // theGraph: this.newsletter.getB64().chartB64,
          // theNSDQ: this.newsletter.getB64().nsdqB64
        };
        // console.log(body);
        var url = this.config.url;

        let emails = [];
        emails.push(email);

        this.http.post<any>(url + 'user/firstnews', { emails: emails }).pipe(first()).subscribe(data => {
        }, err => {
          // console.log('error in register');
          // console.log(err);
        })
        setTimeout(() => (this.show = false), 3000);
      }, err => {
        // console.log('error in register');
        this.fail = true;
        this.subText = "Please try again!";
        setTimeout(() => (this.fail = false), 3000);
      })

    }
  }

  async loginCheck(){
    let username = await localStorage.getItem('username');

    if(username){
        this.router.navigateByUrl('/user');

    }else{
        this.router.navigateByUrl('/login');
        
    }
}


}
