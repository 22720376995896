import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Router, RouterModule, ROUTES } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { FormBuilder } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

import { HomeModule } from './home/home.module';
import { LoginComponent } from './login/login.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { EtoroComponent } from './etoro/etoro.component';
import { BacktestingComponent } from './backtesting/backtesting.component';
import { BrochureComponent } from './brochure/brochure.component';
import { ContactComponent } from './contact/contact.component';

import { NgwWowModule } from 'ngx-wow';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';

import { OwlModule } from 'ngx-owl-carousel';
import { SurveyComponent } from './survey/survey.component';
import { MultimediaComponent } from './multimedia/multimedia.component';
import {HttpClientModule} from '@angular/common/http';
import { ChartsComponent } from './charts/charts.component';
import { UserStatsComponent } from './user-stats/user-stats.component';
import { CosmosComponent } from './cosmos/cosmos.component';
import { TestingComponent } from './testing/testing.component';
import { RegisterComponent } from './register/register.component';
import { AdminComponent } from './admin/admin.component';
import { NewHomeComponent } from './new-home/new-home.component';
import { IndexVideoComponent, IndexVideoContent } from './index-video/index-video.component';

import { DatePipe, LocationStrategy, PathLocationStrategy, HashLocationStrategy } from '@angular/common';
import { MainGraphComponent } from './main-graph/main-graph.component';
import { ContactusComponent } from './contactus/contactus.component';

import { NgxCaptureModule } from 'ngx-capture';
import { ImagekitioAngularModule } from 'imagekitio-angular';
import { AcademyComponent } from './academy/academy.component';
import { NavbarService } from './navbarservice.service';
import { PlotsService } from './plots.service';
import { NewsletterService } from './newsletter.service';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { SeoComponent } from './seo/seo.component';
import { Seo1Component } from './seo1/seo1.component';
import { Seo2Component } from './seo2/seo2.component';
import { Seo3Component } from './seo3/seo3.component';
import { Seo4Component } from './seo4/seo4.component';
import { Seo5Component } from './seo5/seo5.component';
import { Seo6Component } from './seo6/seo6.component';
import { Seo7Component } from './seo7/seo7.component';
import { Seo8Component } from './seo8/seo8.component';
import { Seo9Component } from './seo9/seo9.component';
import { Seo10Component } from './seo10/seo10.component';
import { Seo11Component } from './seo11/seo11.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { ErrorComponent } from './error/error.component';
import { ScrolledToDirective } from './scrolled-to.directive';
import { JoinusintermediateComponent } from './joinusintermediate/joinusintermediate.component';
import { RiskInfomartionComponent } from './risk-infomartion/risk-infomartion.component';
import { GlobalRiskComponent } from './global-risk/global-risk.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { GlobalNewsComponent } from './global-news/global-news.component';
import { ScrollIntoViewDirective } from './scroll-into-view.directive';
import { MainPortfolioComponent } from './main-portfolio/main-portfolio.component';


@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    LandingComponent,
    ProfileComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,
    AboutUsComponent,
    EtoroComponent,
    BacktestingComponent,
    BrochureComponent,
    ContactComponent,
    HomeComponent,
    QuestionnaireComponent,
    SurveyComponent,
    MultimediaComponent,
    ChartsComponent,
    UserStatsComponent,
    CosmosComponent,
    TestingComponent,
    RegisterComponent,
    AdminComponent,
    NewHomeComponent,
    IndexVideoComponent,
    IndexVideoContent,
    MainGraphComponent,
    ContactusComponent,
    AcademyComponent,
    NewsletterComponent,
    SeoComponent,
    Seo1Component,
    Seo2Component,
    Seo3Component,
    Seo4Component,
    Seo5Component,
    Seo6Component,
    Seo7Component,
    Seo8Component,
    Seo9Component,
    Seo10Component,
    Seo11Component,
    SitemapComponent,
    ErrorComponent,
    ScrolledToDirective,
    JoinusintermediateComponent,
    RiskInfomartionComponent,
    GlobalRiskComponent,
    UploadFileComponent,
    GlobalNewsComponent,
    ScrollIntoViewDirective,
    MainPortfolioComponent

  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    RouterModule,
    AppRoutingModule,
    HomeModule,
    NgwWowModule,
    OwlModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxCaptureModule,
    ImagekitioAngularModule.forRoot({
      publicKey:"public_tRMh52in+3GvjO73/Ao79aqjGf4=",
      urlEndpoint:"https://ik.imagekit.io/cosmicetoro",
      authenticationEndpoint:"https://backseat-trader.com"
    })
  ],
  entryComponents: [
    IndexVideoContent,
  ],
  // providers: [NgbActiveModal, DatePipe, Location, {provide: LocationStrategy, useClass: HashLocationStrategy}, NavbarService, PlotsService, NewsletterService],
  providers: [NgbActiveModal, DatePipe, Location, NavbarService, PlotsService, NewsletterService],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
